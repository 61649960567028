<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>
<style >
body,html{
  height: 100%;
  padding: 0;
  margin: 0;
}
  #app{
    height: 100%;
  }
</style>
